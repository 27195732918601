import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

// Axios 
import axios from 'axios'
import VueAxios from 'vue-axios'

const app = createApp(App)
  .use(IonicVue)
  .use(router);

// Create Axios
const axiosClient = axios.create({
    baseURL: "/api/v1", 
});
app.use(VueAxios, axiosClient)

// Teonet global functions
const appVersion = "0.2.7"

// getAppVersion return this web application version
app.config.globalProperties.getAppVersion = function() {
	return appVersion
}

// TODO: temporaly function should be deleted
app.config.globalProperties.GetApps = function (address: string, cmd: number |
  string, data: any, cb: (pac: any) => any/* , answerType: string */) {
  
  const payload = { address: address, cmd: cmd, data: data };
  axiosClient.post('/apps', payload).then(resp => {
    cb(resp.data);
  });
}

// Teonet SendCmdTo function for web
app.config.globalProperties.SendCmdTo = function (address: string, cmd: number |
  string, data: any, cb: (pac: any) => any, answerType: string) {
  
  const form_data = new FormData();
  form_data.append('address', address);
  form_data.append('cmd', ""+cmd);
  form_data.append('data', data);
  form_data.append('answerType', answerType);

  const url = '/SendCmdTo';
  if (cb) {
    axiosClient.post(url, form_data).then(resp => { cb(resp.data); });
  } else {
    axiosClient.post(url, form_data);
  }
}

// Mount app
router.isReady().then(() => {
  app.mount('#app');
});
