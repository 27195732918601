import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/applications'
  },
  {
    path: '/folder/:id',
    component: () => import ('../views/FolderPage.vue')
  },
  {
    path: '/hello/:id',
    component: () => import ('../views/HelloPage.vue')
  },

  {
    path: '/applications',
    component: () => import ('../views/ApplicationsPage.vue')
  },
  {
    path: '/application/:id',
    component: () => import('../views/ApplicationPage.vue')
  },

  {
    path: '/hosts',
    component: () => import ('../views/HostsPage.vue')
  },
  {
    path: '/host/:id',
    component: () => import('../views/HostPage.vue')
  },

  {
    path: '/monitor',
    component: () => import('../views/SelectMonitor.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
